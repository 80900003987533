import { AutoAwesomeMosaic } from '@mui/icons-material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

import {
  BRIGHT_GRAY,
  FLASH_WHITE,
  LINEN_YELLOW,
  MISTY_ROSE_PINK,
} from './colors';

export const WorkOrderType = {
  Package: 0,
  Rate: 1,
  ExternalPart: 2,
  Tyre: 3,
  Empty: 9,
};

export const WorkOrderTypeIcons = {
  [WorkOrderType.Package]: <ListAltIcon />,
  [WorkOrderType.Rate]: <WatchLaterIcon />,
  [WorkOrderType.ExternalPart]: <LocalShippingIcon className='flipped-icon' />,
  [WorkOrderType.Tyre]: <AutoAwesomeMosaic className='rotated-icon' />,
  [WorkOrderType.Empty]: <WatchLaterIcon className='light-watch' />,
};

export enum StatusEnum {
  Draft,
  InReview,
  Rejected,
  Deleted,
  Modified,
  Approved,
  Invoiced,
  NotFound = 99,
}

export const StatusProps = {
  [StatusEnum.Draft]: {
    key: 'draft',
    color: FLASH_WHITE,
  },
  [StatusEnum.InReview]: {
    key: 'inReview',
    color: LINEN_YELLOW,
  },
  [StatusEnum.Rejected]: {
    key: 'rejected',
    color: MISTY_ROSE_PINK,
  },
  [StatusEnum.Deleted]: {
    key: 'deleted',
    color: MISTY_ROSE_PINK,
  },
  [StatusEnum.Modified]: {
    key: 'modified',
    color: LINEN_YELLOW,
  },
  [StatusEnum.Approved]: {
    key: 'approved',
    color: BRIGHT_GRAY,
  },
  [StatusEnum.Invoiced]: {
    key: 'invoiced',
    color: BRIGHT_GRAY,
  },
};
