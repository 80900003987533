import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DetailsProps } from 'types/requestDetails';

import { useAuth0 } from '@auth0/auth0-react';

import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import withLoading, { WithLoadingPropTypes } from 'app/hocs/withLoading';
import RequestDetailsHeader from 'app/shared/components/RequestDetailsHeader';
import RequestDetailsSection from 'app/shared/components/RequestDetailsSection';

import { useDispatch, useSelector } from 'hooks/global';
import useHistoryPath from 'hooks/useHistoryPath';

import {
  getRequestDetailsById,
  getRequestDetailsByIdHistory,
} from 'store/actions/serviceRequests.actions';
import {
  selectRequestComments,
  selectRequestId,
} from 'store/selectors/serviceRequests.selector';

import CommentsSection from './CommentsSection';
import Details from './Details';

const StyledContainer = styled(Container)({
  height: '100%',
});

const RequestDetails = ({
  isLoading,
  setIsLoading,
  isTotalDetails,
  t,
  totalValues,
  tableConfig,
  getCellContentValue,
}: DetailsProps & WithLoadingPropTypes) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { isHistoryPath } = useHistoryPath();

  const { serviceRequestId } = useParams();

  const { workshopRemarks, companyRemarks } = useSelector(
    selectRequestComments
  );
  const requestId = useSelector(selectRequestId);

  useEffect(() => {
    if (!requestId) {
      setIsLoading?.(true);
      getAccessTokenSilently().then(token => {
        const params = {
          requestId: serviceRequestId || '',
          config: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        };

        const action = isHistoryPath
          ? getRequestDetailsByIdHistory
          : getRequestDetailsById;

        dispatch(action(params)).finally(() => setIsLoading?.(false));
      });
    }
  }, [
    dispatch,
    getAccessTokenSilently,
    requestId,
    serviceRequestId,
    setIsLoading,
    isHistoryPath,
  ]);

  return (
    <StyledContainer disableGutters maxWidth={false}>
      <RequestDetailsHeader isLoading={isLoading} />
      {!isTotalDetails && <Divider />}
      <RequestDetailsSection>
        <Details
          isLoading={isLoading}
          t={t}
          totalValues={totalValues}
          isTotalDetails={isTotalDetails}
          tableConfig={tableConfig}
          getCellContentValue={getCellContentValue}
        />
      </RequestDetailsSection>
      {isTotalDetails && (
        <CommentsSection
          companyRemarks={companyRemarks}
          workshopRemarks={workshopRemarks}
        />
      )}
    </StyledContainer>
  );
};

export default withLoading(RequestDetails);
