import { TFunction } from 'react-i18next';
import {
  RequestItemDetailsTotalRow,
  WorkOrderLine,
} from 'types/serviceRequest';

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import Row from 'app/shared/components/Row';

import { WorkOrderType, WorkOrderTypeIcons } from 'constants/requestDetails';

import formatValueToCurrency from 'utilities/formatValueToCurrency';
import getRequestStatusChip from 'utilities/getRequestStatusChip';

import BruttoPriceCell from './components/BruttoPriceCell';
import TimeCell from './components/TimeCell';

const formatterOpt = {
  style: undefined,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const getTotalValues = (totals: RequestItemDetailsTotalRow[]) =>
  totals?.map((item: Partial<RequestItemDetailsTotalRow>) => ({
    ...item,
    description: item.packageDescription,
    workOrderLines:
      item.workOrderLines?.map(workOrderLine => ({
        ...workOrderLine,
        ...workOrderLine.costs,
        discount: workOrderLine.costs?.discountPercentage,
      })) || [],
  }));

export const getCellContentValue =
  (
    row: Partial<WorkOrderLine> | Partial<RequestItemDetailsTotalRow>,
    t: TFunction<'translation', string>
  ) =>
  // eslint-disable-next-line react/display-name
  (key: string) => {
    const value = row[key as keyof typeof row] as string | number;

    const isEmptyRow = row.type === WorkOrderType.Empty;
    const isPackageRow = row.type === WorkOrderType.Package;
    const isExternalPartRow = row.type === WorkOrderType.ExternalPart;

    const shouldReturnNull =
      (isEmptyRow && ['articleNumber', 'brutto', 'discount'].includes(key)) ||
      (!isExternalPartRow && key === 'quantity');

    if (shouldReturnNull) {
      return null;
    }

    if (isExternalPartRow && key === 'description') {
      return (
        <Row className='vertically-align-row'>
          <KeyboardReturnIcon className='flipped-icon external-part-cell-icon' />
          {` `}
          {value}
        </Row>
      );
    }

    if (isEmptyRow && key === 'description') {
      return <div className='is-empty-cell'>{value}</div>;
    }

    if (key === 'status') {
      return getRequestStatusChip(value, t);
    }

    if (key === 'type') {
      return WorkOrderTypeIcons[value as number];
    }

    if (key === 'price') {
      const bruttoVatIncl = (row.bruttoVatIncl as number) || 0;

      if (!!bruttoVatIncl && bruttoVatIncl !== value) {
        return <BruttoPriceCell brutto={value} bruttoVatIncl={bruttoVatIncl} />;
      }

      return formatValueToCurrency(value);
    }

    if (['rate', 'brutto', 'netto'].includes(key)) {
      return formatValueToCurrency(value);
    }

    if (key === 'time') {
      const formattedValue = formatValueToCurrency(value, formatterOpt);

      const isValueExist = !!value && !!row.agreedTime;
      const isTimeInfoShown = isValueExist && row.agreedTime !== value;

      if (isTimeInfoShown) {
        const agreedTime = formatValueToCurrency(
          row.agreedTime as number,
          formatterOpt
        );

        return <TimeCell time={formattedValue} agreedTime={agreedTime} />;
      }

      return formattedValue;
    }

    if (key === 'quantity') {
      return formatValueToCurrency(value, formatterOpt);
    }

    if (key === 'discount') {
      const discountValue = value || row.discountPercentage || 0;

      if (isPackageRow) {
        return formatValueToCurrency(value);
      }

      const discountPersentageValue = Number(discountValue) / 100;

      const discountStrOptions = {
        ...formatterOpt,
        style: 'percent',
      };

      return formatValueToCurrency(discountPersentageValue, discountStrOptions);
    }

    return value;
  };
