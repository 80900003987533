import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

import formatValueToCurrency from 'utilities/formatValueToCurrency';
import getRequestStatusChip from 'utilities/getRequestStatusChip';

import {
  CodeType,
  RequestItemDetailsTotalRow,
  WorkOrderLine,
} from '../../../types/serviceRequest';

export const formatTotalValues = (totals: RequestItemDetailsTotalRow[]) =>
  totals.flatMap((item: RequestItemDetailsTotalRow) => {
    const { activityCode, damageCode } = item;

    const activityCodeStr = `${(activityCode as CodeType).id} - ${
      (activityCode as CodeType).description
    }`;

    const damageCodeStr = `${(damageCode as CodeType).id} - ${
      (damageCode as CodeType).description
    }`;

    return {
      ...item,
      activityCode: activityCodeStr,
      damageCode: damageCodeStr,
    };
  });

export const getCellContentValue =
  (
    row: Partial<WorkOrderLine> | Partial<RequestItemDetailsTotalRow>,
    t: TFunction<'translation', string>
  ) =>
  // eslint-disable-next-line react/display-name
  (key: string): ReactNode => {
    const value = row[key as keyof typeof row];

    if (key === 'status') {
      return getRequestStatusChip(value, t);
    }

    if (key === 'costs') {
      return (value as string[])
        .map((item: string) => formatValueToCurrency(item))
        .join(' + ');
    }

    if (key === 'totalCost') {
      return formatValueToCurrency(value as string);
    }

    return value as ReactNode;
  };
