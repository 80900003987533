import { useEffect, useState } from 'react';
import short from 'short-uuid';

import ServiceRequestsList from 'app/components/ServiceRequestsList';

import { useDispatch } from 'hooks/global';

import { getServiceRequestsList } from 'store/actions/serviceRequests.actions';
import {
  selectActualServiceRequests,
  selectPaginationOptions,
} from 'store/selectors/serviceRequests.selector';
import { resetSearch } from 'store/slices/search.slice';
import { resetList } from 'store/slices/serviceRequests.slice';

const ActualRequestsList = () => {
  const dispatch = useDispatch();
  const [dataGridKey, setDataGridKey] = useState<string>(short.generate());

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  return (
    <ServiceRequestsList
      dataGridKey={dataGridKey}
      selectPagination={selectPaginationOptions}
      selectRows={selectActualServiceRequests}
      setDataGridKey={setDataGridKey}
      resetList={resetList}
      getList={getServiceRequestsList}
      totalDetailPath='requestDetails'
      pageTitle='pageTitle'
    />
  );
};

export default ActualRequestsList;
