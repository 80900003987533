import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Path from '../../../routes/path';

interface ErrorFallbackProps {
  error?: Error;
  onReset?: () => void;
}

const StyledBox = styled(Box)({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

const ErrorFallback = ({ error, onReset }: ErrorFallbackProps) => {
  const navigate = useNavigate();

  const button = onReset ? (
    <Button onClick={onReset}>Try again</Button>
  ) : (
    <Button onClick={() => navigate(Path.home)}>Go to main screen</Button>
  );

  return (
    <StyledBox>
      <Typography>Something went wrong:</Typography>
      <pre>{error?.message}</pre>
      {button}
    </StyledBox>
  );
};

export default ErrorFallback;
