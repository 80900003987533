import { useEffect, useState } from 'react';
import short from 'short-uuid';

import ServiceRequestsList from 'app/components/ServiceRequestsList';

import { useDispatch } from 'hooks/global';
import useHistoryPath from 'hooks/useHistoryPath';

import { selectHistoryPaginationOptions } from '../../../store/selectors/historyView.selector';
import { getServiceRequestsListHistory } from 'store/actions/serviceRequests.actions';
import { selectHistoryServiceRequests } from 'store/selectors/historyView.selector';
import { resetList } from 'store/slices/historyView.slice';
import { resetSearch } from 'store/slices/search.slice';

const InvoiceRequestsList = () => {
  const dispatch = useDispatch();
  const { isHistoryPath } = useHistoryPath();
  const [dataGridKey, setDataGridKey] = useState<string>(short.generate());

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  return (
    <ServiceRequestsList
      dataGridKey={dataGridKey}
      selectRows={selectHistoryServiceRequests}
      selectPagination={selectHistoryPaginationOptions}
      setDataGridKey={setDataGridKey}
      resetList={resetList}
      isHistoryView={isHistoryPath}
      getList={getServiceRequestsListHistory}
      totalDetailPath='historyRequestDetails'
      pageTitle='historyPageTitle'
    />
  );
};

export default InvoiceRequestsList;
