export const ACTUAL_PER_PAGE = 'actualPerPage';
export const ACTUAL_PAGE = 'actualPage';
export const HISTORICAL_PER_PAGE = 'historicalPerPage';
export const HISTORICAL_PAGE = 'historicalPage';

export const setStorageItem = (value: number, name: string) => {
  window.sessionStorage.setItem(name, value.toString());
};

export const getStorageItem = (name: string) => {
  const value = window.sessionStorage.getItem(name);
  return value ? parseInt(value) : null;
};

export const removeStorageItem = (name: string) => {
  window.sessionStorage.removeItem(name);
};

export const clearStorage = () => {
  window.sessionStorage.removeItem(ACTUAL_PER_PAGE);
  window.sessionStorage.removeItem(ACTUAL_PAGE);
  window.sessionStorage.removeItem(HISTORICAL_PER_PAGE);
  window.sessionStorage.removeItem(HISTORICAL_PAGE);
};
