import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { Typography } from '@mui/material';

import { WHITE } from '../../../constants/colors';
import { COMMON_DATE_TIME_FORMAT } from '../../../constants/dateTime';

const dateTimeLabelStyledisplays = {
  display: 'block',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '19px',
  color: WHITE,
  textTransform: 'capitalize',
};

const DateTimeLabel = ({ locale }: { locale: string }) => {
  const defaultDate = dayjs().locale(locale).format(COMMON_DATE_TIME_FORMAT);

  const [dateTime, setDateTime] = useState(defaultDate);

  useEffect(() => {
    const interval = setInterval(
      () => setDateTime(dayjs().locale(locale).format(COMMON_DATE_TIME_FORMAT)),
      1000
    );

    return () => clearInterval(interval);
  }, [locale]);

  return (
    <Typography noWrap sx={dateTimeLabelStyledisplays}>
      {dateTime}
    </Typography>
  );
};

export default DateTimeLabel;
