import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RequestItem, RequestsListResponse } from 'types/serviceRequest';

import { getServiceRequestsList } from 'store/actions/serviceRequests.actions';

import { ServiceRequestsState } from '../../types/serviceRequest';

const requestItemsAdaptor = createEntityAdapter<RequestItem>();

export const initialState: ServiceRequestsState = {
  requests: requestItemsAdaptor.getInitialState(),
  isLoading: false,
  page: 0,
  totalItems: 0,
  totalPages: 0,
};

export const serviceRequestsSlice = createSlice({
  name: 'serviceRequests',
  initialState,
  reducers: {
    setServiceRequests: (state, { payload }: PayloadAction) => ({
      ...state,
      serviceRequests: payload,
    }),
    resetList: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(
        getServiceRequestsList.fulfilled,
        (state: ServiceRequestsState, { payload }) => {
          const { items, paging } = payload as RequestsListResponse;
          state.isLoading = false;
          state.page = paging.page;
          state.totalItems = paging.totalItems;
          state.totalPages = paging.totalPages;
          requestItemsAdaptor.setAll(state.requests, items);
          return state;
        }
      )
      .addCase(
        getServiceRequestsList.pending,
        (state: ServiceRequestsState) => ({
          ...state,
          isLoading: true,
        })
      )
      .addCase(
        getServiceRequestsList.rejected,
        (state: ServiceRequestsState) => ({
          ...state,
          isLoading: false,
        })
      );
  },
});

export const { resetList, setServiceRequests } = serviceRequestsSlice.actions;

export default serviceRequestsSlice.reducer;
