import { generatePath } from 'react-router-dom';

export const ROUTING_PARAMS = {
  serviceRequestId: 'serviceRequestId',
};

export default class Path {
  static home = '/';
  static noMatch = '*';
  static dashboard = '/dashboard';
  static history = '/history';
  static serviceRequest = '/service-request';
  static requestDetails = {
    total: `${Path.serviceRequest}/:${ROUTING_PARAMS.serviceRequestId}/total`,
    workCard: `${Path.serviceRequest}/:${ROUTING_PARAMS.serviceRequestId}/card`,
    details: `${Path.serviceRequest}/:${ROUTING_PARAMS.serviceRequestId}/invoice`,
  };

  static historyRequestDetails = {
    total: `${Path.history}${Path.serviceRequest}/:${ROUTING_PARAMS.serviceRequestId}/total`,
    workCard: `${Path.history}${Path.serviceRequest}/:${ROUTING_PARAMS.serviceRequestId}/card`,
    details: `${Path.history}${Path.serviceRequest}/:${ROUTING_PARAMS.serviceRequestId}/invoice`,
  };

  static generate(path: string, params?: object | undefined) {
    return generatePath(path, params);
  }
}
