import { useMemo } from 'react';

import RequestDetails from 'app/shared/components/RequestDetails';

import { useSelector } from 'hooks/global';
import { useAppTranslation } from 'hooks/useAppTranslation';

import { selectRequestDetails } from 'store/selectors/serviceRequests.selector';

import { getCellContentValue, getTotalValues } from './utils';

const tableConfig = {
  status: { align: undefined },
  type: { align: 'center' },
  articleNumber: { align: 'left' },
  description: { align: 'left' },
  time: { align: 'right' },
  rate: { align: 'right' },
  quantity: { align: 'right' },
  price: { align: 'right' },
  brutto: { align: 'right' },
  discount: { align: 'right' },
  netto: { align: 'right' },
};

const RobOfferteGegevens = () => {
  const { t } = useAppTranslation('offerteGegevens');

  const { totals } = useSelector(selectRequestDetails);

  const formattedTotalValues = useMemo(() => getTotalValues(totals), [totals]);

  return (
    <RequestDetails
      t={t}
      totalValues={formattedTotalValues}
      tableConfig={tableConfig}
      getCellContentValue={getCellContentValue}
    />
  );
};

export default RobOfferteGegevens;
