import PopperCell from 'app/shared/components/PopperCell';

import { useAppTranslation } from 'hooks/useAppTranslation';

import formatValueToCurrency from 'utilities/formatValueToCurrency';

const BruttoPriceCell = ({
  brutto,
  bruttoVatIncl,
}: {
  brutto: string | number;
  bruttoVatIncl: string | number;
}) => {
  const { t } = useAppTranslation('offerteGegevens');

  const popperLabel = `${t('originalValue')}: ${formatValueToCurrency(
    bruttoVatIncl || '0'
  )}`;

  return (
    <PopperCell label={popperLabel} value={formatValueToCurrency(brutto)} />
  );
};

export default BruttoPriceCell;
