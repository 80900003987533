import { styled } from '@mui/material/styles';

import Row from 'app/shared/components/Row';

import { CHINESE_WHITE, JET_GRAY, LOTION_GREY, WHITE } from 'constants/colors';

import { EX_VAT_ID, INC_VAT_ID } from './TotalAmountRow';

const StyledWrapperRow = styled(Row)(({ location }: { location?: string }) => ({
  margin: location === 'bottom' ? '24px 0 0' : '0 0 12px',
  alignItems: 'center',

  [`#${EX_VAT_ID}, #${INC_VAT_ID}`]: {
    boxSizing: 'border-box',
    textAlign: 'center',
    lineHeight: '16px',
    color: JET_GRAY,
    WebkitTextFillColor: 'inherit',
    border: `1px solid ${CHINESE_WHITE}`,
    height: '42px',
    padding: '12px 20px',
    margin: '0px -1px',
    textTransform: 'none',
  },

  [`#${INC_VAT_ID}`]: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    background: WHITE,
  },

  [`#${EX_VAT_ID}`]: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '16px',
    background: LOTION_GREY,
  },
}));

export default StyledWrapperRow;
