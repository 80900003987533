import { ReactNode } from 'react';

import { useSelector } from 'hooks/global';

import { selectRequestDetails } from 'store/selectors/serviceRequests.selector';

import StyledBox from './StyledBox';
import ActionsRow from './components/ActionsRow';
import TotalAmountRow from './components/TotalAmountRow';

interface RequestDetailsSectionProps {
  children?: ReactNode;
}

const RequestDetailsSection = ({ children }: RequestDetailsSectionProps) => {
  const {
    amountInclTax,
    id,
    amount,
    robRequestNr,
    startDate,
    invoiceNumber,
    invoiceDate,
    externalReference,
  } = useSelector(selectRequestDetails);

  return (
    <StyledBox component='section'>
      <ActionsRow
        requestNum={robRequestNr}
        invoiceNumber={invoiceNumber}
        serviceRequestId={id}
        startDate={startDate}
        invoiceDate={invoiceDate}
        externalReference={externalReference}
      />
      <TotalAmountRow incVatAmount={amountInclTax} exVatAmount={amount} />
      {children}
      <TotalAmountRow
        location='bottom'
        incVatAmount={amountInclTax}
        exVatAmount={amount}
      />
    </StyledBox>
  );
};

export default RequestDetailsSection;
