import SvgWrapper from '../../SvgWrapper';

interface SvgProps {
  width: number;
  height: number;
}

const EuropeanStarsSvg = ({ width, height }: SvgProps) => {
  return (
    <SvgWrapper>
      <svg
        version='1.1'
        id='european_stars'
        viewBox='0 0 300 300'
        x='0px'
        y='0px'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width={width}
        height={height}
      >
        <g id='s' transform='translate(150,30)' fill='#fc0'>
          <g id='c'>
            <path id='t' d='M 0,-20 V 0 H 10' transform='rotate(18 0,-20)' />
            <use xlinkHref='#t' transform='scale(-1,1)' />
          </g>
          <use xlinkHref='#c' transform='rotate(72)' />
          <use xlinkHref='#c' transform='rotate(144)' />
          <use xlinkHref='#c' transform='rotate(216)' />
          <use xlinkHref='#c' transform='rotate(288)' />
        </g>
        <use xlinkHref='#s' transform='rotate(30 150,150) rotate(330 150,30)' />
        <use xlinkHref='#s' transform='rotate(60 150,150) rotate(300 150,30)' />
        <use xlinkHref='#s' transform='rotate(90 150,150) rotate(270 150,30)' />
        <use
          xlinkHref='#s'
          transform='rotate(120 150,150) rotate(240 150,30)'
        />
        <use
          xlinkHref='#s'
          transform='rotate(150 150,150) rotate(210 150,30)'
        />
        <use
          xlinkHref='#s'
          transform='rotate(180 150,150) rotate(180 150,30)'
        />
        <use
          xlinkHref='#s'
          transform='rotate(210 150,150) rotate(150 150,30)'
        />
        <use
          xlinkHref='#s'
          transform='rotate(240 150,150) rotate(120 150,30)'
        />
        <use xlinkHref='#s' transform='rotate(270 150,150) rotate(90 150,30)' />
        <use xlinkHref='#s' transform='rotate(300 150,150) rotate(60 150,30)' />
        <use xlinkHref='#s' transform='rotate(330 150,150) rotate(30 150,30)' />
      </svg>
    </SvgWrapper>
  );
};

export default EuropeanStarsSvg;
