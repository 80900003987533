import { styled } from '@mui/material';

import { AIR_FORCE_BLUE, QUARTZ_GREY } from '../../../../../constants/colors';

interface PlateStyleProps {
  plateColor?: string;
  countryCodeColor?: string;
  licenceNumberColor?: string;
}

const Plate = styled('div')<PlateStyleProps>(
  ({ plateColor, countryCodeColor, licenceNumberColor }) => ({
    border: `2px solid ${QUARTZ_GREY}`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    height: '22px',
    width: '94px',
    backgroundColor: plateColor,

    '& .licence_number': {
      margin: 'auto',
      fontFamily: 'UKNumberPlate',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '14px',
      letterSpacing: '0.1em',
      color: licenceNumberColor,
    },

    '& .license-plate-side-bar': {
      height: '100%',
      width: '16%',
      backgroundColor: AIR_FORCE_BLUE,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexDirection: 'column',

      '& .country-code': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '8px',
        lineHeight: '7px',
        color: countryCodeColor,
      },
    },
  })
);

export default Plate;
