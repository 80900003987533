interface NumberFormatProps extends Intl.NumberFormatOptions {
  locale?: string | string[];
  style?: string;
  currency?: string;
}

export default function formatValueToCurrency(
  value: number | string | null,
  options?: NumberFormatProps
) {
  const { locale = 'en-En' } = options || {};

  const isEmptyOrUndefined = value === null || typeof value === 'undefined';

  if (isEmptyOrUndefined) {
    return null;
  }

  return new Intl.NumberFormat(locale, {
    ...options,
    style: Object.hasOwn(options || {}, 'style') ? options?.style : 'currency',
    currency: Object.hasOwn(options || {}, 'currency')
      ? options?.currency
      : 'EUR',
  }).format(Number(value));
}
