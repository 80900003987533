import React, { useCallback } from 'react';

import { LabelDisplayedRowsArgs, TablePagination } from '@mui/material';

import { useAppTranslation } from '../../../hooks/useAppTranslation';

import { ROWS_PER_PAGE_OPTIONS } from '../../../constants/general';

interface PaginationProps {
  rowsCount: number;
  currentPage: number;
  rowsPerPage: number;
  totalPages: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const Pagination = ({
  currentPage,
  rowsCount,
  rowsPerPage,
  totalPages,
  handleChangePage,
  handleChangeRowsPerPage,
}: PaginationProps) => {
  const { t } = useAppTranslation('dashboard');

  const labelDisplayedRows = useCallback(
    ({ count, page }: LabelDisplayedRowsArgs) => {
      const resultLabel = t(
        `pageControls.${count === 1 ? 'oneResult' : 'multipleResults'}`
      );

      const curPage = totalPages ? page + 1 : 0;

      return `${count || 0} ${resultLabel} | ${curPage}/${totalPages || 0}`;
    },
    [t, totalPages]
  );

  return (
    <TablePagination
      showFirstButton
      showLastButton
      component='div'
      count={rowsCount}
      page={currentPage}
      labelRowsPerPage=''
      labelDisplayedRows={labelDisplayedRows}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      size='small'
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default Pagination;
