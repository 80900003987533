import { CSSObject, Drawer as MuiDrawer } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';

import { DARK_CHARCOAL_GRAY } from '../../../constants/colors';
import {
  COMMON_HEADER_MAX_HEIGHT,
  MAX_SIDEBAR_WIDTH,
} from '../../../constants/general';

const openedMixin = (theme: Theme): CSSObject => ({
  width: MAX_SIDEBAR_WIDTH,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  overflowX: 'hidden',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const generalDrawerPaperStyles = {
  top: COMMON_HEADER_MAX_HEIGHT,
  height: `calc(100% - ${COMMON_HEADER_MAX_HEIGHT}px)`,
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: MAX_SIDEBAR_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  '& .logo-wrapper': {
    '&.lkq-logo-wrapper': {
      margin: 'auto',
      marginLeft: '16px',
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 41,
    marginTop: 22,
    marginBottom: 54,
  },

  '& .sidebar-menu-wrapper': {
    height: '100%',

    '& > ul': {
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      padding: 0,

      '.history-list-item': {
        marginTop: 'auto',
      },

      '.list-item': {
        borderLeft: '4px solid transparent',

        '.MuiListItemButton-root': {
          background: 'transparent',
          border: 0,

          '.MuiListItemIcon-root': {
            color: DARK_CHARCOAL_GRAY,
          },
        },
      },

      '.MuiListItemButton-root': {
        padding: 0,
        justifyContent: 'normal',
      },

      '.menu-nav-link': {
        height: '100%',
        width: '100%',
        minWidth: 0,
        flexFlow: 'row',
        alignItems: 'center',
        display: 'flex',
        padding: '0 0 0 20px',
        justifyContent: 'center',
        textAlign: 'left',
        textTransform: 'capitalize',
        textDecoration: 'none',
      },
    },
  },

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...generalDrawerPaperStyles,
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...generalDrawerPaperStyles,
      ...closedMixin(theme),
    },
  }),
}));

export default Drawer;
