import { createSelector } from '@reduxjs/toolkit';
import { DropdownOption, RootState } from 'types/global';

import { User } from '@auth0/auth0-spa-js';

export const selectUser = ({ userSettings }: RootState) => userSettings.user;

export const selectUserFullName = createSelector(selectUser, (user: User) => {
  if (!user.given_name && !user.family_name) {
    return user.name || user.email;
  }

  return `${user.given_name} ${user.user_metadata?.NamePrefix || ''} ${
    user.family_name
  }`;
});

export const selectLocales = ({ userSettings }: RootState) =>
  userSettings.localeOptions;

export const selectCurrentLocaleId = ({ userSettings }: RootState) =>
  userSettings.currentLocaleId;

export const selectCurrentFleet = createSelector(
  selectUser,
  (user: User) => user?.user_metadata?.CompanyName || ''
);

export const selectCurrentLanguage = createSelector(
  [selectLocales, selectCurrentLocaleId],
  (locales, curLocaleId) =>
    locales.find(({ id }: DropdownOption) => id === curLocaleId)?.label
);
