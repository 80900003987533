import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import { AppDispatch, RootState } from '../types/global';

export const useDispatch: () => AppDispatch = useReduxDispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
