import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SearchState {
  isLoading: boolean;
  searchValue: string;
}

const initialState: SearchState = {
  isLoading: false,
  searchValue: '',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearch: () => ({
      ...initialState,
    }),
    setSearchValue: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      searchValue: payload,
    }),
  },
});

export const { resetSearch, setSearchValue } = searchSlice.actions;

export default searchSlice.reducer;
