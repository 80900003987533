import PopperCell from 'app/shared/components/PopperCell';

import { useAppTranslation } from 'hooks/useAppTranslation';

const TimeCell = ({
  time,
  agreedTime,
}: {
  time: string | null;
  agreedTime: string | null;
}) => {
  const { t } = useAppTranslation('offerteGegevens');

  const popperLabel = `${t('originalValue')}: ${agreedTime || '0'}`;

  return <PopperCell label={popperLabel} value={time} />;
};

export default TimeCell;
