import ReactLoading from 'react-loading';

import { styled } from '@mui/material/styles';

import { YALE_BLUE } from 'constants/colors';

import Row from '../Row';

const StyledRow = styled(Row, {
  shouldForwardProp: prop => prop !== 'stretch',
})(({ stretch }) => ({
  height: stretch ? '100vh' : '100%',
  alignItems: 'center',
}));

interface LoaderProps {
  stretch?: boolean | number;
  width?: string | number;
  height?: string | number;
}

const Loader = ({ width, height, stretch }: LoaderProps) => {
  return (
    <StyledRow stretch={stretch} variant='center'>
      <ReactLoading
        type='spin'
        color={YALE_BLUE}
        width={width}
        height={height}
      />
    </StyledRow>
  );
};

export default Loader;
