export const apiUrl = process.env.REACT_APP_API_URL;

const DEFAULT_ERROR = 'Er is iets fout gegaan';

async function http<T>(path: string, config: RequestInit): Promise<T> {
  const request = new Request(apiUrl + path, config);
  try {
    const response = await fetch(request);
    if (!response.ok) {
      if (response.statusText) {
        throw new Error(response.statusText);
      } else {
        const resString = await response.text();
        const responseJsonError = resString ? JSON.parse(resString) : DEFAULT_ERROR;
        console.error({ response });
        throw new Error(responseJsonError);
      }
    }
    const string = await response.text();
    return JSON.parse(string || '{}');
  } catch (err) {
    console.error('http module error:', err);
    throw err;
  }
}

export function post<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init: RequestInit = {
    ...config,
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({
      ...config?.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    mode: 'cors',
  };

  return http<U>(path, init);
}

export function put<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init: RequestInit = {
    ...config,
    method: 'PUT',
    body: JSON.stringify(body),
    headers: new Headers({
      ...config?.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    mode: 'cors',
  };

  return http<U>(path, init);
}

export function get<U>(path: string, config?: RequestInit): Promise<U> {
  const init: RequestInit = {
    ...config,
    method: 'GET',
    headers: new Headers({
      ...config?.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    mode: 'cors',
  };

  return http<U>(path, init);
}

export function remove<U>(
  path: string,
  config?: RequestInit
): Promise<U> {
  const init: RequestInit = {
    ...config,
    method: 'DELETE',
    headers: new Headers({
      ...config?.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    mode: 'cors',
  };

  return http<U>(path, init);
}
