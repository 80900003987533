import { AppBarProps, AppBar as MuiAppBar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { WHITE, YALE_BLUE } from '../../../constants/colors';
import { COMMON_HEADER_MAX_HEIGHT } from '../../../constants/general';

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'fixed',
  padding: '0 40px',
  height: COMMON_HEADER_MAX_HEIGHT,
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: YALE_BLUE,

  '& .user-settings-wrapper': {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& button': {
      color: WHITE,
      textTransformation: 'auto',
      marginRight: '28px',
      '&:last-child': {
        marginRight: 0,
      },
    },

    '& .user-fleet': {
      marginRight: 21,
      display: 'flex',

      svg: {
        marginRight: 8,
      },
    },
  },
}));

export default AppBar;
