interface LimitParams {
  limit: number;
  padString?: string;
  padPosition?: string;
  padLength?: number;
}

export const limitStrWithPad = (
  str: string,
  { limit, padString = '*', padPosition = 'right', padLength = 4 }: LimitParams
): string => {
  if (
    typeof str !== 'string' ||
    typeof limit !== 'number' ||
    (padLength && typeof padLength !== 'number')
  ) {
    throw new Error('Invalid arguments specified');
  }

  if (['left', 'right'].indexOf(padPosition) === -1) {
    throw new Error('Pad position should be either left or right');
  }

  if (typeof padString !== 'string') {
    padString = String(padString);
  }

  const strLength = str.length;

  if (strLength < limit) {
    return str;
  } else if (strLength > limit) {
    const subStr = str.slice(0, limit);
    const padRepeats = padString.repeat(padLength);
    return padPosition === 'left' ? padRepeats + subStr : subStr + padRepeats;
  }

  return str;
};
