import { createSlice } from '@reduxjs/toolkit';
import { RequestItemDetails } from 'types/serviceRequest';

import {
  getRequestDetailsById,
  getRequestDetailsByIdHistory,
  getServiceRequestsList,
  getServiceRequestsListHistory,
} from '../actions/serviceRequests.actions';

export const initialState: RequestItemDetails = {
  id: '',
  amountInclTax: 0,
  amount: 0,
  companyRemarks: '',
  workshopRemarks: '',
  invoiceNumber: '',
  robRequestNr: '',
  startDate: '',
  invoiceDate: '',
  externalReference: '',
  vehicle: {
    licensePlate: {
      number: '',
      countryCode: '',
    },
    mark: '',
    model: '',
    type: '',
    vin: '',
    power: 0,
    engineCode: '',
    licenceDate: '',
    motExpirationDate: '',
    odometerValue: 0,
    odometerUnit: '',
  },
  address: {
    city: '',
    address: '',
    phoneNo: '',
    name: '',
    postalCode: '',
  },
  invoiceAccount: {
    name: '',
    companyName: '',
    type: '',
  },
  totals: [],
  vatDetails: {
    products: '',
    services: '',
    tyres: '',
    discount: '',
    exVatTotal: '',
    vatTotal: '',
    inclVatTotal: '',
  },
};

export const requestDetailsSlice = createSlice({
  name: 'requestDetails',
  initialState,
  reducers: {
    resetRequestDetails: () => ({
      ...initialState,
    }),
  },
  extraReducers: builder => {
    builder.addCase(
      getRequestDetailsById.fulfilled,
      (state: RequestItemDetails, { payload }) => ({
        ...state,
        ...(payload as Partial<RequestItemDetails>),
      })
    );
    builder.addCase(
      getRequestDetailsByIdHistory.fulfilled,
      (state: RequestItemDetails, { payload }) => ({
        ...state,
        ...(payload as Partial<RequestItemDetails>),
      })
    );
    builder.addCase(getServiceRequestsList.fulfilled, () => ({
      ...initialState,
    }));
    builder.addCase(getServiceRequestsListHistory.fulfilled, () => ({
      ...initialState,
    }));
  },
});

export const { resetRequestDetails } = requestDetailsSlice.actions;

export default requestDetailsSlice.reducer;
