import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import {
  CodeType,
  RequestItemDetailsTotalRow,
  WorkOrderLine,
} from 'types/serviceRequest';

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import Row from 'app/shared/components/Row';

import { WorkOrderType, WorkOrderTypeIcons } from 'constants/requestDetails';

import formatValueToCurrency from 'utilities/formatValueToCurrency';
import getRequestStatusChip from 'utilities/getRequestStatusChip';

export const getTotalValues = (totals: RequestItemDetailsTotalRow[]) =>
  totals.flatMap((item: RequestItemDetailsTotalRow) => {
    const { activityCode, damageCode } = item;

    const activityCodeStr = `${(activityCode as CodeType).id} - ${
      (activityCode as CodeType).description
    }`;

    const damageCodeStr = `${(damageCode as CodeType).id} - ${
      (damageCode as CodeType).description
    }`;

    return {
      ...item,
      activityCode: activityCodeStr,
      damageCode: damageCodeStr,
      description: item.packageDescription,
      workOrderLines: item.workOrderLines || [],
    };
  });

export const getCellContentValue =
  (
    row: Partial<WorkOrderLine> | Partial<RequestItemDetailsTotalRow>,
    t: TFunction<'translation', string>
  ) =>
  // eslint-disable-next-line react/display-name
  (key: string): ReactNode => {
    const value = row[key as keyof typeof row] as ReactNode;

    const isExternalPartRow = row.type === WorkOrderType.ExternalPart;

    if (isExternalPartRow && key === 'description') {
      return (
        <Row>
          <KeyboardReturnIcon className='flipped-icon' />
          {` `}
          {value}
        </Row>
      );
    }

    const isEmptyRow = row.type === WorkOrderType.Empty;

    if (isEmptyRow && key === 'description') {
      return <div className='is-empty-cell'>{value}</div>;
    }

    const shouldReturnNull =
      isEmptyRow && (key === 'articleNumber' || key === 'quantity');

    if (shouldReturnNull) {
      return null;
    }

    if (key === 'status') {
      return getRequestStatusChip(value, t);
    }

    if (key === 'type') {
      return WorkOrderTypeIcons[value as number];
    }

    if (key === 'totalCost') {
      return formatValueToCurrency(value as string);
    }

    if (key === 'quantity') {
      const opt = {
        style: undefined,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return formatValueToCurrency(value as string, opt);
    }

    if (
      (key === 'location' || key === 'warranty') &&
      (row as RequestItemDetailsTotalRow)?.status
    ) {
      return value ?? '';
    }

    return value;
  };
