import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SeverityLevel } from '@sentry/types/types/severity';

type SentryError = {
  response: {
    data: {
      msg?: string;
      errorInfo?: string;
    };
  };
};

export const SentryHelper = {
  init: (dsn: string) => {
    if (dsn) {
      Sentry.init({
        dsn: dsn,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
      });
    }
  },

  logErrorEvent: ({
    componentName,
    error,
    level = 'error',
  }: {
    componentName: string;
    error: SentryError;
    level?: SeverityLevel;
  }) => {
    Sentry.configureScope(scope => scope.setTransactionName(componentName));
    Sentry.captureException(error, {
      fingerprint: [componentName],
      level,
      extra: {
        message: error?.response?.data?.msg,
        errorInfo: error?.response?.data?.errorInfo,
      },
    });
  },
};

export default Sentry.withErrorBoundary;
