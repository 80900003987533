import React, { useState } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ANTI_FLASH_WHITE } from '../../../constants/colors';
import { COMMON_HEADER_MAX_HEIGHT } from '../../../constants/general';

import Header from '../Header';
import SideBar from '../SideBar';

const StyledBox = styled(Box)({
  display: 'flex',
  height: '100%',
  minHeight: '100vh',

  main: {
    flexGrow: 1,
    padding: 24,
    marginTop: `${COMMON_HEADER_MAX_HEIGHT}px`,
    backgroundColor: ANTI_FLASH_WHITE,
  },
});

interface DashboardProps {
  children?: React.ReactNode;
}

const Dashboard = ({ children }: DashboardProps) => {
  const [isSideBarOpen, setIsOpen] = useState<boolean>(false);

  const handleCollapseSideBar = () => {
    setIsOpen(!isSideBarOpen);
  };

  return (
    <StyledBox>
      <Header />
      <SideBar isOpen={isSideBarOpen} setIsOpen={handleCollapseSideBar} />
      <Box component='main'>{children}</Box>
    </StyledBox>
  );
};

export default Dashboard;
