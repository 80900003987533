import { Box, Typography } from '@mui/material';

import {
  MAIZE_CRAYOLA_YELLOW,
  QUARTZ_GREY,
  WHITE,
} from '../../../../constants/colors';

import EuropeanStarsSvg from './EuropeanStarsSvg';
import StyledPlate from './StyledPlate';

interface LicensePlateProps {
  plateId: string | undefined;
  plateColor?: string;
  countryCode?: string;
  countryCodeColor?: string;
  licenceNumberColor?: string;
  height?: number;
}

const LicensePlate = ({
  plateColor = MAIZE_CRAYOLA_YELLOW,
  countryCode = 'NL',
  plateId,
  countryCodeColor = WHITE,
  licenceNumberColor = QUARTZ_GREY,
}: LicensePlateProps) => {
  return (
    <StyledPlate
      plateColor={plateColor}
      countryCodeColor={countryCodeColor}
      licenceNumberColor={licenceNumberColor}
    >
      <Box className='license-plate-side-bar'>
        <EuropeanStarsSvg width={10} height={10} />
        <Typography className='country-code'>{countryCode}</Typography>
      </Box>
      <Typography className='licence_number'>{plateId}</Typography>
    </StyledPlate>
  );
};

export default LicensePlate;
