import { styled } from '@mui/material';

import {
  DAVYS_GREY,
  JET_GRAY,
  SPANISH_GRAY,
} from '../../../../constants/colors';
import { CHINESE_WHITE, WHITE } from 'constants/colors';
import Row from 'app/shared/components/Row';

const StyledHeaderWrapper = styled(Row)({
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '32px 24px',
  gap: '32px',
  flex: 'none',
  order: 0,
  alignSelf: 'stretch',
  flexGrow: 0,
  backgroundColor: WHITE,

  '.MuiTypography-root': {
    fontSize: '14px',
  },

  '.section': {
    flexDirection: 'row',
    alignSelf: 'stretch',
  },

  '.car-logo': {
    marginRight: '8px',

    img: {
      height: 28,
    },

    '.MuiSvgIcon-root': {
      width: 28,
      height: 28,
    },
  },

  '.car-brand-label': {
    textTransform: 'uppercase',
    marginBottom: '16px',
  },

  '.logo-and-plate, .vin-number': {
    flexFlow: 'row',
    alignItems: 'center',
  },

  '.vin-number': {
    marginBottom: '4px',

    button: {
      minWidth: 0,
      marginLeft: '10px',
      padding: '5px',
      color: DAVYS_GREY,
      borderColor: DAVYS_GREY,

      '.MuiSvgIcon-root': {
        width: 16,
        height: 16,
      },
    },
  },

  '.milage': {
    button: {
      color: DAVYS_GREY,
      borderColor: DAVYS_GREY,
      pointerEvents: 'none',
      '&:first-of-type': {
        marginRight: '4px',
      },
    },
  },

  '.address-details, .invoice-details': {
    minWidth: '205px',
  },

  '.vehicle-plate': {
    flexDirection: 'column',
  },

  '.vehicle-plate, .address-details': {
    marginRight: '32px',
  },

  '.vehicle-technical-details': {
    minWidth: '288px',
  },

  '.vehicle-technical-details, .address-details, .invoice-details': {
    padding: '10px 16px',
    border: `1px solid ${CHINESE_WHITE}`,
    borderRadius: '4px',

    ul: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '6px',
      color: JET_GRAY,

      li: {
        justifyContent: 'space-between',
      },
    },
  },

  '.vehicle-technical-details-label, .address-details-label, .invoice-details-label':
    {
      display: 'flex',
      alignItems: 'center',

      svg: {
        color: SPANISH_GRAY,
        marginRight: '6px',
      },
    },
});

export default StyledHeaderWrapper;
