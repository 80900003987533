import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DropdownOption } from 'types/global';

import { User } from '@auth0/auth0-spa-js';

import { LocaleId } from '../../constants/general';

export interface UserSettingsState {
  localeOptions: DropdownOption[];
  currentLocaleId: string;
  user: User;
}

export const initialState: UserSettingsState = {
  localeOptions: [
    { id: LocaleId.NL, label: 'NL' },
    { id: LocaleId.EN, label: 'EN' },
  ],
  currentLocaleId: LocaleId.EN,
  user: {},
};

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => ({
      ...state,
      user: payload,
      currentLocaleId:
        payload.user_metadata?.DefaultLanguage || initialState.currentLocaleId,
    }),
    setCurrentLocaleId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      currentLocaleId: payload,
    }),
    setLocaleOptions: (
      state,
      { payload }: PayloadAction<DropdownOption[]>
    ) => ({
      ...state,
      localeOptions: payload,
    }),
  },
});

export const {
  setUser,
  setLocaleOptions,
  setCurrentLocaleId,
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
