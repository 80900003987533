import React from 'react';

import { Menu, MenuItem, PopoverOrigin } from '@mui/material';
import { DropdownOption } from 'types/global';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
} as PopoverOrigin;

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
} as PopoverOrigin;

interface ProfileDropdownMenuProps {
  anchorEl: null | HTMLElement;
  menuId: string | undefined;
  selectedOption?: string;
  isMenuOpen: boolean;
  options?: DropdownOption[];
  onClose?(): void;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
}

const ProfileDropdownMenu = ({
  anchorEl,
  menuId,
  isMenuOpen,
  options = [],
  selectedOption,
  onClick,
  onClose,
}: ProfileDropdownMenuProps) => {
  return (
    <Menu
      anchorEl={anchorEl}
      elevation={0}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      id={menuId}
      aria-labelledby={menuId}
      open={isMenuOpen}
      onClose={onClose}
    >
      {options.map(({ id, label }: DropdownOption) => (
        <MenuItem
          selected={id === selectedOption}
          data-id={id}
          key={`${id}-${label}`}
          onClick={onClick}
        >
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ProfileDropdownMenu;
