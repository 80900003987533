import { StatusProps } from '../constants/requestDetails';

export const dashboardColorStyle = () => {
  const res: Record<string, { backgroundColor: string }> = {};
  for (const key in StatusProps) {
    res[`& .request-status--${key}--cell div`] = {
      backgroundColor:
        StatusProps[key as unknown as keyof typeof StatusProps]?.color,
    };
  }
  return res;
};

export const detailsColorStyle = () => {
  const res: Record<string, { backgroundColor: string }> = {};
  for (const key in StatusProps) {
    res[`& .work-order-line-status--${key}--cell`] = {
      backgroundColor:
        StatusProps[key as unknown as keyof typeof StatusProps]?.color,
    };
  }
  return res;
};
