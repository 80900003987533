import { styled } from '@mui/material/styles';
import { MUIStyledCommonProps } from '@mui/system';

import { JET_GRAY } from '../../../constants/colors';

interface SideBarBottomProps extends MUIStyledCommonProps {
  open: boolean;
}

const SideBarBottom = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})<SideBarBottomProps>(({ theme, open }) => ({
  display: 'flex',
  marginTop: 'auto',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: open ? theme.spacing(0, 1) : 0,
  ...theme.mixins.toolbar,
  '&	.MuiButton-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiButton-endIcon': {
      margin: 0,
      color: JET_GRAY,
    },
  },
}));

export default SideBarBottom;
