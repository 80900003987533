export const ANTI_FLASH_WHITE = '#F0F0F0';
export const SMOKE_WHITE = '#F3F3F3';
export const WHITE = '#ffffff';
export const CHINESE_WHITE = '#E1E1E1';
export const GHOST_WHITE = '#F8F9FA';

export const YALE_BLUE = '#1B508D';
export const STEEL_BLUE = '#2D74B3';
export const STEEL_BLUE_LIGHT = 'rgba(45, 116, 179, 0.1)';
export const AIR_FORCE_BLUE = '#093891';
export const ALICE_BLUE = '#EDF4FB';
export const GREEN_BLUE = '#1068B7';

export const JET_GRAY = '#363334';
export const SPANISH_GRAY = '#939094';
export const QUARTZ_GREY = '#4B4849';
export const LOTION_GREY = '#FBFBFB';

export const MAIZE_CRAYOLA_YELLOW = '#F8D047';

// <----- Request Status Colors START ----->
export const FLASH_WHITE = '#ECF1F5';
export const MISTY_ROSE_PINK = '#FFE7E7';
export const LAVENDER_BLUSH_PINK = '#FFEEF6';
export const BRIGHT_GRAY = '#EAF3EB';
export const LAVENDER_WEB_BLUE = '#E5F0FE';
export const LINEN_YELLOW = '#FEF0E6';
export const PAPAYA_WHIP_YELLOW = '#FFF2D3';
// <----- Request Status Colors END ----->

export const PHILIPPINE_SILVER_GREY = '#B6AFB3';
export const DAVYS_GREY = '#5C5C5C';
export const DARK_CHARCOAL_GRAY = '#323232';
