import React from 'react';

import { WithAuth0Props, withAuth0 } from '@auth0/auth0-react';

import ErrorFallback from 'app/components/ErrorFallback';
import Loader from 'app/shared/components/Loader';

function withAuth<P extends object>(Component: React.ComponentType<P>) {
  const Wrapper = ({ auth0 }: WithAuth0Props) => {
    const { error, isAuthenticated, isLoading, loginWithRedirect, ...rest } =
      auth0;

    if (isLoading) {
      return <Loader stretch={1} />;
    }

    if (isAuthenticated) {
      return <Component {...(rest as P)} />;
    }

    if (error) {
      return <ErrorFallback error={error} />;
    }

    if (!isAuthenticated) {
      loginWithRedirect();
    }

    return null;
  };

  return withAuth0(Wrapper);
}

export default withAuth;
