import { useState } from 'react';


export interface WithLoadingPropTypes {
  isLoading: boolean;
  setIsLoading: typeof useState;
}

export default function withLoading<
  T extends WithLoadingPropTypes = WithLoadingPropTypes
>(WrappedComponent: React.ComponentType<T>) {
  const EnchancedComponent = (props: Omit<T, keyof WithLoadingPropTypes>) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const extraProps = {
      isLoading: isLoading,
      setIsLoading: setIsLoading,
    };

    return <WrappedComponent {...extraProps} {...(props as T)} />;
  };

  EnchancedComponent.displayName = `withTheme(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return EnchancedComponent;
}