import { RootState } from '../../types/global';

export const selectHistoryServiceRequests = ({ historyView }: RootState) =>
  Object.values(historyView.requests.entities);

export const selectHistoryPaginationOptions = ({ historyView }: RootState) => ({
  page: historyView.page,
  totalItems: historyView.totalItems,
  totalPages: historyView.totalPages,
});
