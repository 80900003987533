import Box from '@mui/material/Box';
import { InputBaseProps } from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)({
  fieldset: {
    border: 0,
  },

  label: {
    transform: 'translate(0px, -1.5px) scale(0.75)',
  },

  input: {
    padding: '4px 0 5px',
    marginTop: '15px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: '0 !important',
  },
})

export default function FilterInputField(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;

  const handleFilterChange: InputBaseProps['onChange'] = event => {
    const { value } = event.target || {};
    applyValue({ ...item, value: value });
  };

  return (
    <StyledBox>
      <TextField
        label='Value'
        placeholder='Filter value'
        value={item.value || ''}
        onChange={handleFilterChange}
      />
    </StyledBox>
  );
}
