import { styled } from '@mui/material/styles';

import Row from 'app/shared/components/Row';

import {
  ALICE_BLUE,
  QUARTZ_GREY,
  SPANISH_GRAY,
  STEEL_BLUE,
  WHITE,
} from 'constants/colors';

const ActionsRowStyledWrapper = styled(Row)({
  padding: '24px 0 32px',
  alignItems: 'center',

  '.first-half': {
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px 0px',

    '.MuiInputBase-root': {
      maxHeight: '44px',
    },

    svg: {
      color: STEEL_BLUE,
    },

    p: {
      marginLeft: '8px',
      marginRight: '12px',
    },
  },

  '.MuiButtonBase-root': {
    lineHeight: 'normal',
    padding: '14px 24px',
    fontSize: '14px',
    textTransform: 'uppercase',
    backgroundColor: WHITE,
    '&.active-button': {
      backgroundColor: ALICE_BLUE,
    },
  },

  '#back-button': {
    padding: '14px 0',
    minWidth: 0,
    backgroundColor: 'transparent',
  },

  '.date-picker': {
    '&.invoice-date': {
      marginRight: '20px',
    },

    backgroundColor: WHITE,
    maxWidth: '173px',
    svg: {
      color: SPANISH_GRAY,
      width: '14px',
      height: '14px',
    },
    '.MuiFormLabel-root': {
      color: QUARTZ_GREY,
      '&[data-shrink="false"]': {
        WebkitTransform: 'translate(14px, 12px) scale(1)',
        lineHeight: 'normal',
      },
    },
  },

  '.inactive-button': {
    color: SPANISH_GRAY,
  },
});

export default ActionsRowStyledWrapper;
