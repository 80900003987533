import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RequestItem, RequestsListResponse } from 'types/serviceRequest';

import { getServiceRequestsListHistory } from 'store/actions/serviceRequests.actions';

import { ServiceRequestsState } from '../../types/serviceRequest';

const requestItemsAdaptor = createEntityAdapter<RequestItem>();

export const initialState: ServiceRequestsState = {
  isLoading: false,
  requests: requestItemsAdaptor.getInitialState(),
  page: 0,
  totalItems: 0,
  totalPages: 0,
};

export const historyViewSlice = createSlice({
  name: 'historyView',
  initialState,
  reducers: {
    resetList: state => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(
        getServiceRequestsListHistory.fulfilled,
        (state: ServiceRequestsState, { payload }) => {
          const { items, paging } = payload as RequestsListResponse;
          state.isLoading = false;
          state.page = paging.page;
          state.totalItems = paging.totalItems;
          state.totalPages = paging.totalPages;
          requestItemsAdaptor.setAll(state.requests, items);
          return state;
        }
      )
      .addCase(
        getServiceRequestsListHistory.pending,
        (state: ServiceRequestsState) => ({
          ...state,
          isLoading: true,
        })
      )
      .addCase(
        getServiceRequestsListHistory.rejected,
        (state: ServiceRequestsState) => ({
          ...state,
          isLoading: false,
        })
      );
  },
});

export const { resetList } = historyViewSlice.actions;

export default historyViewSlice.reducer;
