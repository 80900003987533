import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import dayjs from 'dayjs';
import dayjsEnLocale from 'dayjs/locale/en';
import dayjsNlLocale from 'dayjs/locale/nl';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';

import { store } from './store';

import { theme } from './style/theme';

import './index.css';

import Auth from './auth';
import './i18n';
import router from './routes';

const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY || '';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

dayjs.locale(dayjsEnLocale);
dayjs.locale(dayjsNlLocale);

const container = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Auth>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </StyledEngineProvider>
      </ThemeProvider>
    </Auth>
  </React.StrictMode>
);
