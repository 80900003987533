import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import {
  ANTI_FLASH_WHITE,
  CHINESE_WHITE,
  GHOST_WHITE,
  LOTION_GREY,
  QUARTZ_GREY,
  SMOKE_WHITE,
  WHITE,
} from 'constants/colors';

import { detailsColorStyle } from '../../../../utilities/workOrderLineColors';

const StyledBox = styled(Box)({
  padding: '0 24px 24px 24px',
  background: GHOST_WHITE,
  border: `1px solid ${CHINESE_WHITE}`,

  tr: {
    backgroundColor: WHITE,

    td: {
      cursor: 'default',
      border: `1px solid ${CHINESE_WHITE}`,
    },
  },

  thead: {
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    borderColor: CHINESE_WHITE,
    tr: {
      backgroundColor: LOTION_GREY,
    },
    th: {
      border: `1px solid ${CHINESE_WHITE}`,
    },
  },

  '.footer-row': {
    backgroundColor: LOTION_GREY,
    td: {
      border: 0,
    },
  },

  'td:has(.is-empty-cell)': {
    backgroundColor: SMOKE_WHITE,
  },

  'tr.is-empty-row': {
    maxHeight: '36px',

    '& ~ tr td': {
      borderTop: 0,
    },

    td: {
      padding: '8px 16px',
      borderBottom: 0,
    },
  },

  '.vertically-align-row': {
    alignItems: 'center',
  },

  '.flipped-icon': {
    transform: 'scaleX(-1)',
  },

  '.external-part-cell-icon': {
    marginRight: '8px',
  },

  '.rotated-icon': {
    transform: 'rotate(270deg)',
  },

  '.rob-row': {
    backgroundColor: ANTI_FLASH_WHITE,
    td: {
      fontWeight: '700',
    },
  },

  '.popper-cell': {
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '.light-watch': {
    color: QUARTZ_GREY,
  },

  ...detailsColorStyle(),
});

export default StyledBox;
