import { styled } from '@mui/material/styles';

export type RowVariant =
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'left'
  | 'right'
  | 'normal'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch';

interface RowProps {
  variant?: RowVariant;
  stretch?: boolean | number;
}

const Row = styled('div')(({ variant = 'flex-start' }: RowProps) => ({
  display: 'flex',
  justifyContent: variant,
}));

export default Row;
