import dayjs from 'dayjs';

import {
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';

import { SERVICE_REQUEST_DATE_FORMAT } from 'constants/dateTime';

import LicensePlate from '../../shared/components/LicensePlate';
import FilterInputField from './components/FilterInputField';
import FilterStatusInputField from './components/FilterStatusInputField';

interface ColumnsProps {
  renderDetailsCell: (params: GridRenderCellParams) => JSX.Element;
  renderStatusCell: (params: GridRenderCellParams) => JSX.Element;
  isHistory: boolean;
}

const operators: GridFilterOperator[] = [
  {
    label: 'Contains',
    value: 'contains',
    getApplyFilterFn:
      (filterItem: GridFilterItem) =>
      (params): boolean =>
        params.value === filterItem.value,
    InputComponent: FilterInputField,
  },
];

const statusOperators: GridFilterOperator[] = [
  {
    label: 'Equal',
    value: 'equal',
    getApplyFilterFn:
      (filterItem: GridFilterItem) =>
      (params): boolean =>
        params.value === filterItem.value,
    InputComponent: FilterStatusInputField,
  },
];

export const getColumns = ({
  renderDetailsCell,
  renderStatusCell,
  isHistory,
}: ColumnsProps): GridColDef[] => [
  {
    field: 'externalReference',
    flex: 1,
    filterOperators: operators,
  },
  {
    field: 'robRequestNr',
    flex: 1,
    filterOperators: operators,
  },
  ...(isHistory
    ? [
        {
          field: 'invoiceNumber',
          flex: 0.8,
          filterOperators: operators,
        },
      ]
    : []),
  {
    field: 'licensePlate',
    flex: 0.7,
    filterOperators: operators,
    renderCell: ({ value }: GridRenderCellParams) => {
      const { number, countryCode } = value;
      return <LicensePlate countryCode={countryCode} plateId={number} />;
    },
  },
  {
    field: 'mark',
    flex: 0.7,
    filterOperators: operators,
  },
  {
    field: 'model',
    flex: 1,
    filterOperators: operators,
  },
  {
    field: 'type',
    flex: 0.7,
    filterOperators: operators,
  },
  {
    field: 'leaseCompany',
    flex: 0.8,
    filterOperators: operators,
  },
  {
    field: 'serviceProvider',
    flex: 0.7,
    filterOperators: operators,
  },
  ...(isHistory
    ? [
        {
          field: 'invoiceDate',
          flex: 0.7,
          filterOperators: operators,
        },
      ]
    : [
        {
          field: 'status',
          cellClassName: 'flex-center--cell request-status--cell',
          flex: 0.7,
          filterOperators: statusOperators,
          renderCell: renderStatusCell,
        },
        {
          field: 'createdAt',
          flex: 0.7,
          filterOperators: operators,
          renderCell: ({ value }: GridRenderCellParams) =>
            dayjs(value).format(SERVICE_REQUEST_DATE_FORMAT),
        },
      ]),
  {
    cellClassName: 'flex-center--cell',
    field: 'details',
    flex: 0.4,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: renderDetailsCell,
  },
];
