import { useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { ChevronLeft, ChevronRight, History, Home } from '@mui/icons-material';
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { useAppTranslation } from '../../../hooks/useAppTranslation';
import useHistoryPath from 'hooks/useHistoryPath';

import { TestId } from '../../../constants/testIds';

import { ReactComponent as CarSysLargeLogo } from '../../../assets/carsys.large.logo.svg';
import { ReactComponent as CarSysSmallLogo } from '../../../assets/carsys.small.logo.svg';
import { ReactComponent as LKQLogo } from '../../../assets/lkq.logo.svg';
import Path from '../../../routes/path';
import ListItem from '../../shared/components/ListItem';
import Drawer from './Drawer';
import SideBarBottom from './SideBarBottom';

const rows = [
  {
    label: 'dashboard',
    icon: <Home />,
    path: Path.dashboard,
    testId: TestId.SIDE_BAR_DASHBOARD_ITEM,
  },
  {
    label: 'history',
    icon: <History />,
    path: Path.history,
    testId: TestId.SIDE_BAR_HISTORY_ITEM,
  },
];

interface SideBarProps {
  isOpen: boolean;
  setIsOpen: (prop: boolean) => void;
}

const SideBar = ({ isOpen, setIsOpen }: SideBarProps) => {
  const { t } = useAppTranslation('navigation');
  const { pathname } = useLocation();
  const { isHistoryPath } = useHistoryPath();

  const handleOpenSideBar = useCallback(
    () => setIsOpen(!isOpen),
    [isOpen, setIsOpen]
  );

  const endIcon = isOpen ? <ChevronLeft /> : <ChevronRight />;
  const topLogoIcon = isOpen ? (
    <CarSysLargeLogo data-testid={TestId.CAR_SYS_LARGE_LOGO} />
  ) : (
    <CarSysSmallLogo data-testid={TestId.CAR_SYS_SMALL_LOGO} />
  );

  return (
    <Drawer variant='permanent' open={isOpen} anchor='left'>
      <Box className='logo-wrapper'>{topLogoIcon}</Box>
      <Box className='sidebar-menu-wrapper'>
        <List>
          {rows.map(({ label, icon, path, testId }) => {
            const showActiveClass =
              pathname.match(path) ||
              (path === Path.dashboard &&
                pathname.match(Path.serviceRequest) &&
                !isHistoryPath);

            return (
              <ListItem
                className={`${label}-list-item ${
                  showActiveClass ? '' : 'list-item'
                }`}
                key={label}
                disablePadding
                open={isOpen}
              >
                <ListItemButton>
                  <NavLink
                    data-testid={testId}
                    className='menu-nav-link'
                    to={path}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={t(label)} />
                  </NavLink>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <SideBarBottom open={isOpen}>
        <Box className='logo-wrapper lkq-logo-wrapper'>
          {isOpen ? <LKQLogo /> : null}
        </Box>
        <Button
          data-testid={TestId.SIDE_BAR_COLLAPSE_BUTTON}
          disableElevation
          disableRipple
          disableFocusRipple
          size='small'
          onClick={handleOpenSideBar}
          endIcon={endIcon}
        />
      </SideBarBottom>
    </Drawer>
  );
};

export default SideBar;
