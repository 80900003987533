import { MouseEvent, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

import Row from 'app/shared/components/Row';

const POPPER_ID = 'mouse-over-popper';

const PopperCell = ({
  label,
  value,
  timeout = 350,
  rowClassName = '',
  popperPlacement = 'bottom',
}: {
  label: string | null;
  value: string | null;
  timeout?: number;
  rowClassName?: string;
  popperPlacement?: PopperPlacementType;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopperOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Row
        className={`popper-cell ${rowClassName}`}
        aria-owns={open ? POPPER_ID : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
      >
        <InfoIcon />
        {value}
      </Row>
      <Popper
        transition
        id={POPPER_ID}
        open={open}
        anchorEl={anchorEl}
        placement={popperPlacement}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={timeout}>
            <Paper>
              <Typography sx={{ p: 1 }}>{label || ''}</Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default PopperCell;
