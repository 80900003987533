import { Navigate, createBrowserRouter } from 'react-router-dom';

import ActualRequestsList from '../app/components/ActualRequestsList/ActualRequestsList';
import ErrorFallback from '../app/components/ErrorFallback';
import InvoiceRequestsList from '../app/components/InvoiceRequestsList/InvoiceRequestsList';
import RobOfferteGegevens from 'app/components/RobOfferteGegevens';
import RobTotalen from 'app/components/RobTotalen';
import RobWerkkaart from 'app/components/RobWerkkaart';

import App from '../App';
import Path from './path';

const dashboardRoutes = [
  {
    path: Path.home,
    element: <Navigate to={Path.dashboard} />,
  },
  {
    path: Path.dashboard,
    element: <ActualRequestsList />,
  },
];

const historyDashboardRoutes = [
  {
    path: Path.history,
    element: <InvoiceRequestsList />,
  },
  {
    path: Path.historyRequestDetails.total,
    element: <RobTotalen />,
  },
  {
    path: Path.historyRequestDetails.workCard,
    element: <RobWerkkaart />,
  },
  {
    path: Path.historyRequestDetails.details,
    element: <RobOfferteGegevens />,
  },
];

const serviceRequestsRoutes = [
  {
    path: Path.requestDetails.total,
    element: <RobTotalen />,
  },
  {
    path: Path.requestDetails.workCard,
    element: <RobWerkkaart />,
  },
  {
    path: Path.requestDetails.details,
    element: <RobOfferteGegevens />,
  },
];

const router = createBrowserRouter([
  {
    path: Path.home,
    element: <App />,
    children: [
      ...dashboardRoutes,
      ...historyDashboardRoutes,
      ...serviceRequestsRoutes,
      {
        path: Path.noMatch,
        element: <ErrorFallback />,
      },
    ],
  },
]);

export default router;
