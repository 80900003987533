export const TestId = {
  APP: 'APP',
  SIDE_BAR_COLLAPSE_BUTTON: 'SIDE_BAR_COLLAPSE_BUTTON',
  CAR_SYS_LARGE_LOGO: 'CAR_SYS_LARGE_LOGO',
  CAR_SYS_SMALL_LOGO: 'CAR_SYS_SMALL_LOGO',
  SIDE_BAR_DASHBOARD_ITEM: 'SIDE_BAR_DASHBOARD_ITEM',
  SIDE_BAR_HISTORY_ITEM: 'SIDE_BAR_HISTORY_ITEM',
  HEADER_LANGUAGE_BUTTON: 'HEADER_LANGUAGE_BUTTON',
  HEADER_USER_BUTTON: 'HEADER_USER_BUTTON',
  HEADER_USER_META: 'HEADER_USER_META',
  DASHBOARD_HEADER_TITLE: 'DASHBOARD_HEADER_TITLE',
  SEARCH_INPUT: 'SEARCH_INPUT',
  ROB_TOTALS_NAV_BTN: 'ROB_TOTALS_NAV_BTN',
  ROB_WERKKAART_NAV_BTN: 'ROB_WERKKAART_NAV_BTN',
  ROB_OFFERTE_GEGEVENS_NAV_BTN: 'ROB_OFFERTE_GEGEVENS_NAV_BTN',
};
