import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../types/global';

export const selectAllServiceRequests = (state: RootState) =>
  state.serviceRequests.requests;

export const selectActualServiceRequests = createSelector(
  selectAllServiceRequests,
  requests => Object.values(requests.entities)
);

export const selectPaginationOptions = (state: RootState) => ({
  page: state.serviceRequests.page,
  totalItems: state.serviceRequests.totalItems,
  totalPages: state.serviceRequests.totalPages,
});

export const selectRequestDetails = (state: RootState) => state.requestDetails;

export const selectRequestVatDetails = (state: RootState) =>
  state.requestDetails.vatDetails;

export const selectRequestId = (state: RootState) => state.requestDetails.id;

export const selectRequestComments = ({ requestDetails }: RootState) => ({
  companyRemarks: requestDetails.companyRemarks,
  workshopRemarks: requestDetails.workshopRemarks,
});
