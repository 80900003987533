import { useCallback, useEffect, useState } from 'react';

import { Search as SearchIcon } from '@mui/icons-material';
import { styled } from '@mui/material';

import SearchIconWrapper from './SearchIconWrapper';

import { useDispatch, useSelector } from '../../../../hooks/global';

import { selectSearchValue } from 'store/selectors/search.selector';
import { setSearchValue } from 'store/slices/search.slice';

import { ENTER_KEY } from 'constants/general';
import { TestId } from 'constants/testIds';

import StyledInputBase from './StyledInputBase';

const inputProps = { 'aria-label': 'search' };

interface SearchProps {
  placeHolder: string;
  handleSearch: (searchValue: string) => void;
}

const StyledSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.common.white,
  marginLeft: 0,
  width: '100%',
  maxWidth: '561px',
}));

const Search = ({ placeHolder, handleSearch }: SearchProps) => {
  const dispatch = useDispatch();
  const [isSearchActive, setIsSearchActive] = useState(false);

  const searchValue = useSelector(selectSearchValue);

  const handleSearchBlur = () => setIsSearchActive(false);

  const handleSearchFocus = () => setIsSearchActive(true);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(setSearchValue(value));
  };

  const handleKeyUp = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key === ENTER_KEY && isSearchActive) {
        handleSearch(searchValue);
      }
    },
    [handleSearch, isSearchActive, searchValue]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp, false);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  return (
    <StyledSearch>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        data-testid={TestId.SEARCH_INPUT}
        placeholder={placeHolder}
        inputProps={inputProps}
        value={searchValue}
        onFocus={handleSearchFocus}
        onBlur={handleSearchBlur}
        onChange={handleSearchChange}
      />
    </StyledSearch>
  );
};

export default Search;
