import AppsIcon from '@mui/icons-material/Apps';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Pagination from 'app/components/Pagination';
import Row from 'app/shared/components/Row';

import { useAppTranslation } from '../../../../hooks/useAppTranslation';

interface FooterProps {
  handleResetDataGrid: () => void;
  totalItems: number;
  currentPage: number;
  rowsPerPage: number;
  totalPages: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export default function DataGridFooter({
  handleResetDataGrid,
  currentPage,
  totalItems,
  totalPages,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}: FooterProps) {
  const { t } = useAppTranslation('dashboard');

  return (
    <Row className='data-grid-custom-footer' variant='space-between'>
      <Button variant='text' onClick={handleResetDataGrid}>
        <Typography className='reset-filtering'>
          <AppsIcon />
          {t('pageControls.reset')}
        </Typography>
      </Button>
      <Pagination
        currentPage={currentPage}
        rowsCount={totalItems}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Row>
  );
}
