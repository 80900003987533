import { ListItemProps, ListItem as MuiListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  JET_GRAY,
  STEEL_BLUE,
  STEEL_BLUE_LIGHT,
} from '../../../../constants/colors';

interface ListItemExtendedProps extends ListItemProps {
  open: boolean;
}

const ListItem = styled(MuiListItem, {
  shouldForwardProp: prop => prop !== 'open',
})<ListItemExtendedProps>(({ open }) => ({
  '&	.MuiListItemButton-root': {
    alignItems: 'center',
    height: 60,
    boxSizing: 'border-box',
    padding: '0  0  0 20px',
    background: STEEL_BLUE_LIGHT,
    borderLeft: `4px solid ${STEEL_BLUE}`,
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
  },
  '&	.MuiListItemIcon-root': {
    width: 20,
    minWidth: 0,
    marginRight: open ? 8 : 'auto',
    justifyContent: 'center',
    color: STEEL_BLUE,
  },
  '&	.MuiListItemText-root': {
    opacity: open ? 1 : 0,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: JET_GRAY,
  },
}));

export default ListItem;
