export const MAX_SIDEBAR_WIDTH = 240;

export const COMMON_HEADER_MAX_HEIGHT = 60;

export const VIN_NUMBER_LIMIT = 8;

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 20, 25, 50, 100];

export const ENTER_KEY = 'Enter';

export const LocaleId = {
  EN: 'en_GB',
  NL: 'nl_NL',
};

export const Order = {
  desc: 0,
  asc: 1,
};

export const NextLink = {
  or: 0,
  and: 1,
};

export const ApiOperatorTypes = {
  greater: 0,
  less: 1,
  equal: 2,
  notEquals: 3,
  greaterOrEquals: 4,
  lessOrEquals: 5,
  like: 6,
  iLike: 7,
};

export const OperatorTypes = {
  contains: ApiOperatorTypes.iLike,
  equal: ApiOperatorTypes.equal,
};
