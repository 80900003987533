import { combineReducers } from '@reduxjs/toolkit';

import requestDetailsSlice from './slices/requestDetails.slice';
import serviceRequestsReducer from './slices/serviceRequests.slice';
import userSettingsSliceReducer from './slices/userSettings.slice';
import historyViewSlice from './slices/historyView.slice';
import searchSlice from './slices/search.slice';

const createReducer = () =>
  combineReducers({
    userSettings: userSettingsSliceReducer,
    serviceRequests: serviceRequestsReducer,
    requestDetails: requestDetailsSlice,
    search: searchSlice,
    historyView: historyViewSlice,
  });

export default createReducer;
