import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import Row from 'app/shared/components/Row';

import { useAppTranslation } from 'hooks/useAppTranslation';

import { CHINESE_WHITE, SPANISH_GRAY, WHITE } from 'constants/colors';

interface CommentsSectionProps {
  companyRemarks: string;
  workshopRemarks: string;
}

const StyledRow = styled(Row)({
  padding: '24px',
  marginTop: '24px',
  background: WHITE,
  flexDirection: 'column',
  borderBottom: `1px solid ${CHINESE_WHITE}`,

  '.MuiTextField-root': {
    '&:first-of-type': {
      marginBottom: '20px',
    },
    minHeight: '59px',
    maxWidth: '588px',

    input: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: SPANISH_GRAY,
    },
  },
});

const CommentsSection = ({
  workshopRemarks,
  companyRemarks,
}: CommentsSectionProps) => {
  const { t } = useAppTranslation('robTotalen');

  return (
    <StyledRow>
      <TextField
        disabled
        label={t('remarks.workshop')}
        placeholder={t('remarks.workshop')}
        value={workshopRemarks}
      />
      <TextField
        disabled
        label={t('remarks.company')}
        placeholder={t('remarks.company')}
        value={companyRemarks}
      />
    </StyledRow>
  );
};

export default CommentsSection;
