import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from 'api';
import { ResponseError, ThunkApiType } from 'types/global';
import {
  GetRequestParams,
  RequestItemDetails,
  RequestListRequest,
  RequestsListResponse,
} from 'types/serviceRequest';

export const ActualRequests = {
  GET_LIST: 'workOrders/List',
  GET_REQUEST_DETAILS: 'workOrders',
};

export const HistoryRequests = {
  GET_LIST: 'invoices/List',
  GET_REQUEST_DETAILS: 'invoices',
};

const handleError = (thunkApi: ThunkApiType) => (err: Error) => {
  const { message } = err;
  const e: ResponseError = {
    message: message,
  };
  return thunkApi.rejectWithValue(e);
};

const handlePost = async (
  {
    body,
    config,
    path,
  }: { body: RequestListRequest; config: RequestInit; path: string },
  thunkApi: ThunkApiType
) => {
  return await api
    .post<RequestListRequest, RequestsListResponse>(path, body, config)
    .catch(handleError(thunkApi));
};

const handleGet = async (
  { config, path }: { config: RequestInit; path: string },
  thunkApi: ThunkApiType
) => {
  return await api
    .get<RequestItemDetails>(path, config)
    .catch(handleError(thunkApi));
};

export const getServiceRequestsList = createAsyncThunk(
  ActualRequests.GET_LIST,
  ({ body, config }: GetRequestParams, thunkApi) =>
    handlePost({ body, config, path: ActualRequests.GET_LIST }, thunkApi)
);

export const getRequestDetailsById = createAsyncThunk(
  ActualRequests.GET_REQUEST_DETAILS,
  (
    { requestId, config }: { requestId: string; config: RequestInit },
    thunkApi
  ) =>
    handleGet(
      {
        config,
        path: `${ActualRequests.GET_REQUEST_DETAILS}?uuid=${requestId}`,
      },
      thunkApi
    )
);

export const getServiceRequestsListHistory = createAsyncThunk(
  HistoryRequests.GET_LIST,
  ({ body, config }: GetRequestParams, thunkApi) =>
    handlePost({ body, config, path: HistoryRequests.GET_LIST }, thunkApi)
);

export const getRequestDetailsByIdHistory = createAsyncThunk(
  HistoryRequests.GET_REQUEST_DETAILS,
  (
    { requestId, config }: { requestId: string; config: RequestInit },
    thunkApi
  ) =>
    handleGet(
      {
        config,
        path: `${HistoryRequests.GET_REQUEST_DETAILS}?uuid=${requestId}`,
      },
      thunkApi
    )
);
