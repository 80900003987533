import { Search } from '@mui/icons-material';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { DAVYS_GREY } from '../../../../constants/colors';
import { PHILIPPINE_SILVER_GREY, WHITE } from 'constants/colors';

const StyledButton = styled(Button)({
  boxSizing: 'border-box',
  height: 24,
  width: 24,
  minWidth: 0,
  padding: '6px',
  background: WHITE,
  border: `1px solid ${PHILIPPINE_SILVER_GREY}`,
  borderRadius: '4px',

  '& .MuiSvgIcon-root': {
    fontSize: '18px',
    color: DAVYS_GREY,
  },
});

const EditButton = ({ onClick }: ButtonProps) => {
  return (
    <StyledButton onClick={onClick}>
      <Search />
    </StyledButton>
  );
};

export default EditButton;
